@tailwind base;
@tailwind components;
@tailwind utilities;

/* Load Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');

/* app.css or index.css */
body {
  font-family: 'Inter', sans-serif;
}

@layer base {
  p {
    @apply text-base;
  }
}

html {
  scroll-behavior: smooth;
}
